import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  uri = environment.baseUrl + 'images';

  constructor(private http: HttpClient) { }

  // Get all Images
  getImages() {
    return this.http.get(`${this.uri}/`);
  }

  // Get selected image
  getImage(id) {
    return this.http.get(`${this.uri}/image/${id}`);
  }

  // Add image
  addImage(img) {
    console.log("services");
    return this.http.post(`${this.uri}/add`, img);
  }

  // Edite selected image
  editImage(id, img: any) {
    // const updated: Images = <Images>{
    //   name: img.name,
    //   description: img.description,
    //   isSlider: img.isSlider
    // };
    return this.http.put(`${this.uri}/edite/${id}`, img);
  }

  // Delete selected image
  deleteImage(id) {
    return this.http.get(`${this.uri}/delete/${id}`);
  }

}
