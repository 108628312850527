import { Component, OnInit } from '@angular/core';
import { VideoService } from 'src/app/services/video.service';
import { Video } from 'src/app/models/video.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-animation',
  templateUrl: './admin-animation.component.html',
  styleUrls: ['./admin-animation.component.css']
})
export class AdminAnimationComponent implements OnInit {

  videos: Video[];

  constructor(private vis: VideoService,
              private san: DomSanitizer) { }

  ngOnInit() {
    this.vis.getVideos()
      .subscribe(
        (video: Video[]) => {
          this.videos = video;
        }
      );
  }

  deleteVideo(id) {
    const video = this.videos;
    this.vis.deleteVideo(id)
      .subscribe(data => {
        if (data) {
          for (let i = 0; i < video.length; i++) {
            if (video[i]._id === id ) {
              video.splice(i, 1);
            }
          }
        }
      });
  }

  safeUrl(video) {
    return this.san.bypassSecurityTrustResourceUrl(video);
  }

}
