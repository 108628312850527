import { Component, OnInit, ViewChild } from '@angular/core';
import { AboutService } from 'src/app/services/about.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import About from 'src/app/models/about';

@Component({
  selector: 'app-admin-about',
  templateUrl: './admin-about.component.html',
  styleUrls: ['./admin-about.component.css']
})
export class AdminAboutComponent implements OnInit {
  imageData: About;
  editedId: string;
  message: boolean;
  messageText: any;

  editeForm: FormGroup;
  ImageFile: File;
  @ViewChild('proImg') proImg;

  constructor(private abs: AboutService,
              private fb: FormBuilder,
              private router: Router) {
    this.editeForm = this.fb.group({
      'name': ['', Validators.required],
      'description': ['', Validators.required],
      'proImg': ['', Validators.required]
    });
  }

  OnSubmit(value) {
    const img = this.proImg.nativeElement;
    if (img.files && img.files[0]) {
      this.ImageFile = img.files[0];
    }

    const formData = new FormData();
    formData.append('title', value.name);
    formData.append('description', value.description);
    if (img.files && img.files[0]) {
      this.ImageFile = img.files[0];
      formData.append('photo', img.files[0], img.files[0].name);
    }

    this.abs.editPost(this.editedId, formData).subscribe((data) => {
      if (data) {
        this.toggleMessage('Post is updated');
        this.message = true;
      } else {
        this.toggleMessage('Some error');
      }
    });
  }

  toggleMessage(text) {
    setTimeout(() => {
      this.message = false;
      this.messageText = text;
    }, 4000);
  }

  ngOnInit() {
    this.abs.getPosts()
      .subscribe((data: About) => {
        this.editedId = data[0]._id;
        this.editeForm = this.fb.group({
          'name': [data[0].title, Validators.required],
          'description': [data[0].description, Validators.required],
          'proImg': ['', Validators.required]
        });
      });
      this.message = false;
  }
}
