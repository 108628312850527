import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import Image from 'src/app/models/images';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-illustration',
  templateUrl: './admin-illustration.component.html',
  styleUrls: ['./admin-illustration.component.css']
})
export class AdminIllustrationComponent implements OnInit {

  //  before building mast be deleted
  uri = environment.baseUrl;

  images: Image[];

  constructor(private ims: ImagesService) { }

  ngOnInit() {
    this.ims.getImages()
      .subscribe((data: Image[]) => {
        this.images = data;
      });
  }

  deleteImg(id) {
    const img = this.images;
    this.ims.deleteImage(id)
      .subscribe(data => {
        if (data) {
          for (let i = 0; i < img.length; i++) {
            if (img[i]._id === id ) {
              img.splice(i, 1);
            }
          }
        }
      });
  }

}
