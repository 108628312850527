import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DebugContext } from '@angular/core/src/view';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private router: Router) { }

  getUser() {
    return this.http.get(environment.baseUrl + 'get-us');
  }

  changeUser(name, user) {
    return this.http.post<any>(`${environment.baseUrl}users/change-pass/${name}`, user);
  }

  loginUser(user) {
    return this.http.post<any>(`${environment.baseUrl}users/login`, user);
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  logoutUser() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.router.navigate(['/login']);
  }
}
