import { Component, OnInit } from '@angular/core';
import { VideoService } from 'src/app/services/video.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.css']
})
export class AddVideoComponent implements OnInit {

  form: FormGroup;

  constructor(private vis: VideoService, private fb: FormBuilder, private router: Router) {
    this.form = this.fb.group({
      'video_name': ['', Validators.required],
      'video_desc': ['', Validators.required],
      'video_url': ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  OnSubmit(value) {

    const video_for_save = {
      video_name: value.video_name,
      video_desc: value.video_desc,
      video_url: value.video_url
    };
    this.vis.addVideo(video_for_save).subscribe(() => {
      this.router.navigate(['/admin-animation']);
    });
  }

}
