import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  uri = environment.baseUrl + 'abouts';

  constructor(private http: HttpClient) { }

  // Get all Images
  getPosts() {
    return this.http.get(`${this.uri}/`);
  }

  // Get on Post
  getPost(id, post) {
    return this.http.post(`${this.uri}/data/${id}`, post);
  }

  // Add image
  addPost(post: any) {
    return this.http.post(`${this.uri}/add`, post);
  }

  // Edite selected image
  editPost(id, post: any) {
    return this.http.put(`${this.uri}/edite/${id}`, post);
  }

  // Delete selected image
  deletePost(id) {
    return this.http.get(`${this.uri}/delete/${id}`);
  }
}
