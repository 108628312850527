import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavLighterComponent } from './components/nav/nav-lighter/nav-lighter.component';
import { NavDarkComponent } from './components/nav/nav-dark/nav-dark.component';
import { SliderComponent } from './components/slider/slider.component';
import { IllustrationComponent } from './components/illustration/illustration.component';
import { ContactComponent } from './components/contact/contact.component';
import { AnimationComponent } from './components/animation/animation.component';
import { AdminIllustrationComponent } from './admin/admin-illustration/admin-illustration.component';
import { AdminNavComponent } from './admin/admin-nav/admin-nav.component';
import { AdminSliderComponent } from './admin/admin-slider/admin-slider.component';
import { AdminSocComponent } from './admin/admin-soc/admin-soc.component';
import { AdminContactComponent } from './admin/admin-contact/admin-contact.component';
import { AdminAnimationComponent } from './admin/admin-animation/admin-animation.component';
import { AdminAboutComponent } from './admin/admin-about/admin-about.component';
import { AddImageComponent } from './admin/admin-illustration/add-image/add-image.component';
import { EditeImageComponent } from './admin/admin-illustration/edite-image/edite-image.component';
import { AboutComponent } from './components/about/about.component';
import { AddVideoComponent } from './admin/admin-animation/add-video/add-video.component';
import { EditVideoComponent } from './admin/admin-animation/edit-video/edit-video.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: SliderComponent },
  { path: 'illustration', component: IllustrationComponent },
  { path: 'animation', component: AnimationComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'login', component: LoginComponent },
  { path: 'change-pass', component: RegisterComponent },
  { path: 'administrator', redirectTo: '/admin-slider', pathMatch: 'full'},
  { path: 'admin-slider', component: AdminSliderComponent, canActivate: [AuthGuard]},
  { path: 'admin-illustration', component: AdminIllustrationComponent, canActivate: [AuthGuard] },
  { path: 'admin-animation', component: AdminAnimationComponent, canActivate: [AuthGuard] },
  { path: 'admin-about', component: AdminAboutComponent, canActivate: [AuthGuard] },
  { path: 'admin-contact', component: AdminContactComponent, canActivate: [AuthGuard] },
  { path: 'admin-soc', component: AdminSocComponent, canActivate: [AuthGuard] },
  { path: 'edite-image/:id', component: EditeImageComponent, canActivate: [AuthGuard] },
  { path: 'add-image', component: AddImageComponent, canActivate: [AuthGuard] },
  { path: 'edite-video/:id', component: EditVideoComponent, canActivate: [AuthGuard] },
  { path: 'add-video', component: AddVideoComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
    NavLighterComponent, NavDarkComponent, SliderComponent,
    AboutComponent, IllustrationComponent, ContactComponent,
    AnimationComponent, AdminIllustrationComponent, AdminNavComponent,
    AdminSliderComponent, AdminSocComponent, AdminContactComponent,
    AdminAnimationComponent, AdminAboutComponent, AddImageComponent,
    EditeImageComponent, AddVideoComponent, EditVideoComponent, LoginComponent,
    RegisterComponent
];
