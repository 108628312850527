import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Video } from 'src/app/models/video.model';
import { VideoService } from 'src/app/services/video.service';
import { DomSanitizer } from '@angular/platform-browser';
import { sanitizeHtml } from '@angular/core/src/sanitization/sanitization';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.css']
})
export class AnimationComponent implements OnInit {

  videos: Video[];

  constructor(private vis: VideoService,
              private san: DomSanitizer) {}

  safeUrl(video) {
    return this.san.bypassSecurityTrustResourceUrl(video);
  }

  ngOnInit() {
    this.vis.getVideos()
      .subscribe(
        (video: Video[]) => {
          this.videos = video;
      });
  }

}
