import { Component, OnInit } from '@angular/core';
import { SocService } from 'src/app/services/soc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Soc } from 'src/app/models/soc.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-soc',
  templateUrl: './admin-soc.component.html',
  styleUrls: ['./admin-soc.component.css']
})
export class AdminSocComponent implements OnInit {

  socId: string;
  messageText: string;
  message: boolean;
  socForm: FormGroup;


  constructor(private ss: SocService,
    private as: AuthService,
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router) {
    this.socForm = this.fb.group({
      'urlF': ['', Validators.required],
      'urlI': ['', Validators.required],
      'urlB': ['', Validators.required],
      'urlA': ['', Validators.required]
    });
  }

  OnSubmit(value) {

    const data = {
      urlF: value.urlF,
      urlI: value.urlI,
      urlB: value.urlB,
      urlA: value.urlA
    };

    this.ss.editSoc(this.socId, data).subscribe(() => {
      if (data) {
        this.toggleMessage('Post is updated');
        this.message = true;
      } else {
        this.toggleMessage('Some error');
      }
    });
  }

  toggleMessage(text) {
    setTimeout(() => {
      this.message = false;
      this.messageText = text;
    }, 4000);
  }

  ngOnInit() {

    this.ss.getSocs()
      .subscribe((data: Soc) => {
        this.socId = data[0]._id;
        this.socForm = this.fb.group({
          'urlF': [data[0].urlF, Validators.required],
          'urlI': [data[0].urlI, Validators.required],
          'urlB': [data[0].urlB, Validators.required],
          'urlA': [data[0].urlA, Validators.required]
        });
      });
  }

}
