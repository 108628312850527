import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  uri = environment.baseUrl + 'contacts';

  constructor(private http: HttpClient) { }

  // Get all contact
  getContacts() {
    return this.http.get(`${this.uri}/`);
  }

  // Get selected contact
  getContact(id) {
    return this.http.get(`${this.uri}/contact/${id}`);
  }

  // Add image
  addContact(post) {
    return this.http.post(`${this.uri}/add`, post);
  }

  // Edite selected image
  editContact(id, post: any) {
    return this.http.put(`${this.uri}/edite/${id}`, post);
  }

  // Delete selected image
  deleteContact(id) {
    return this.http.get(`${this.uri}/delete/${id}`);
  }
}
