import { Component, OnInit } from '@angular/core';
import { AboutService } from 'src/app/services/about.service';
import About from 'src/app/models/about';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  uri = environment.baseUrl;
  uriM = environment.baseUrl + 'uploads';

  about: About;

  constructor(private abs: AboutService) { }

  ngOnInit() {
    this.abs.getPosts()
      .subscribe((post: About) => {
        this.about = post;
      });
  }

}
