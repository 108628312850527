import { Component, OnInit, ViewChild } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.css']
})
export class AddImageComponent implements OnInit {

  form: FormGroup;
  ImageFile: File;
  @ViewChild('proImg') proImg;

  constructor(private ims: ImagesService, private fb: FormBuilder, private router: Router) {
    this.form = this.fb.group({
      'name': ['', Validators.required],
      'description': ['', Validators.required],
      'isSlider': [''],
      'proImg': ['', Validators.required]
    });
  }

  OnSubmit(value) {
    const img = this.proImg.nativeElement;
    if (img.files && img.files[0]) {
      this.ImageFile = img.files[0];
    }
    const imgFile: File = this.ImageFile;


    const formData = new FormData();
    formData.append('name', value.name);
    formData.append('description', value.description);
    formData.append('isSlider', value.isSlider);
    formData.append('proImg', imgFile, imgFile.name);
    this.ims.addImage(formData).subscribe(() => {
      this.router.navigate(['/admin-illustration']);
    });
  }

  ngOnInit() {
  }

}
