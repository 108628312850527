import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  uri = environment.baseUrl + 'videos';

  constructor(private http: HttpClient) { }

  // Get all Videos
  getVideos() {
    return this.http.get(`${this.uri}/`);
  }

  // Get selected Video
  getVideo(id) {
    return this.http.get(`${this.uri}/video/${id}`);
  }

  // Add Video
  addVideo(video) {
    return this.http.post(`${this.uri}/add`, video);
  }

  // Edite selected Video
  editVideo(id, video: any) {
    return this.http.put(`${this.uri}/edite/${id}`, video);
  }

  // Delete selected Video
  deleteVideo(id) {
    return this.http.get(`${this.uri}/delete/${id}`);
  }
}
