import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SocService } from 'src/app/services/soc.service';
import { Soc } from 'src/app/models/soc.model';

@Component({
  selector: 'app-nav-dark',
  templateUrl: './nav-dark.component.html',
  styleUrls: ['./nav-dark.component.css']
})
export class NavDarkComponent implements OnInit {

  //  before building mast be deleted
  uri = environment.baseUrl + 'uploads';

  urlF: string;
  urlI: string;
  urlB: string;
  urlA: string;

  constructor(private ss: SocService) { }

  ngOnInit() {
    this.ss.getSocs()
      .subscribe((data: Soc) => {
        this.urlF = data[0].urlF;
        this.urlI = data[0].urlI;
        this.urlB = data[0].urlB;
        this.urlA = data[0].urlA;
      });
  }

}
