import { Component, OnInit, ElementRef } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';

import Image from 'src/app/models/images';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-slider',
  templateUrl: './admin-slider.component.html',
  styleUrls: ['./admin-slider.component.css']
})
export class AdminSliderComponent implements OnInit {

  //  before building mast be deleted
  uri = environment.baseUrl;

  images: Image[];
  selected = [];

  constructor(private ism: ImagesService,
              private el: ElementRef) { }

  ngOnInit() {
    this.ism.getImages()
      .subscribe((data: Image[]) => {
        this.images = data;
      });
  }

  log(img, checked) {
    this.selected.push({_id: img._id, isSlider: checked });
  }

  upload() {
    this.selected.forEach(item => {
      this.ism.editImage(item._id, item)
      .subscribe(i => {
      });
    });
  }

}
