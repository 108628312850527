import { Component, OnInit } from '@angular/core';
import { VideoService } from 'src/app/services/video.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Video } from 'src/app/models/video.model';

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.css']
})
export class EditVideoComponent implements OnInit {

  editedId: string;

  editeForm: FormGroup;

  constructor(private vis: VideoService,
              private ar: ActivatedRoute,
              private fb: FormBuilder,
              private router: Router) {
    this.editeForm = this.fb.group({
      'name': ['', Validators.required],
      'description': ['', Validators.required],
      'url': ['']
    });
  }

  OnSubmit(value) {
    const formData = new FormData();
    formData.append('video_name', value.name);
    formData.append('video_desc', value.description);
    formData.append('video_url', value.isSlider);

    const editedVideo = {
      video_name: value.name,
      video_desc: value.description,
      video_url: value.url
    };

    this.vis.editVideo(this.editedId, editedVideo).subscribe(() => {
      this.router.navigate(['/admin-animation']);
    });

  }

  ngOnInit() {
    this.editedId = this.ar.snapshot.paramMap.get('id');
    this.vis.getVideo(this.ar.snapshot.paramMap.get('id'))
      .subscribe((data: Video) => {
        this.editeForm = this.fb.group({
          'name': [data.video_name, Validators.required],
          'description': [data.video_desc, Validators.required],
          'url': [data.video_url]
        });
      });
  }

}
