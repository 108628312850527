import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import Image from '../../models/images';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  //  before building mast be deleted
  uri = environment.baseUrl;

  images: Image[];

  constructor(private ims: ImagesService) { }

  ngOnInit() {
    const imag = [];
    this.ims.getImages()
      .subscribe((data: Image[]) => {
        data.forEach(img => {
          if (img.isSlider === true) {
            imag.push(img);
          }
        });
        this.images = imag;
      });
  }

}
