import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import Contact from 'src/app/models/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  fullName: string;
  addres: string;
  phone: string;
  email: string;

  constructor(private cs: ContactService) { }

  ngOnInit() {
    this.cs.getContacts()
      .subscribe((data: Contact) => {
        this.fullName = data[0].fullName;
        this.addres = data[0].addres;
        this.phone = data[0].phone;
        this.email = data[0].email;
      });
  }

  send(value) {
    console.log(value);
  }

}
