import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerUserData = {};
  private username;

  constructor(private _auth: AuthService,
              private _router: Router,
              private ar: ActivatedRoute) { }

  ngOnInit() {
    this.username = localStorage.getItem('username');
  }

  changeUser() {
    this._auth.changeUser(this.username, this.registerUserData)
      .subscribe(
        res => {
          this._auth.logoutUser();
          // this._router.navigate(['/login']);
        },
        err => console.log(err)
      );
  }

}
