import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import Contact from 'src/app/models/contact';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-contact',
  templateUrl: './admin-contact.component.html',
  styleUrls: ['./admin-contact.component.css']
})
export class AdminContactComponent implements OnInit {

  contact: Contact;
  contactForm: FormGroup;
  contactId: number;

  message: boolean;
  messageText: string;

  constructor(private cs: ContactService,
              private ar: ActivatedRoute,
              private fb: FormBuilder) {
  this.contactForm = this.fb.group({
    'fullName': ['', Validators.required],
    'phone': ['', Validators.required],
    'addres': ['', Validators.required],
    'email': ['', Validators.required],
  });
}

OnSubmit(value) {

  const fData = {
    fullName: value.fullName,
    phone: value.phone,
    addres: value.addres,
    email: value.email
  };

  this.cs.editContact(this.contactId, fData).subscribe((data) => {
    if (data) {
      this.toggleMessage('Contact info is updated');
      this.message = true;
    } else {
      this.toggleMessage('Some error');
    }
  });
}

toggleMessage(text) {
  setTimeout(() => {
    this.message = false;
    this.messageText = text;
  }, 4000);
}

  ngOnInit() {
    this.cs.getContacts()
      .subscribe((data: Contact) => {
        this.contactId = data[0]._id;
        this.contactForm = this.fb.group({
          'fullName': [data[0].fullName, Validators.required],
          'phone': [data[0].phone, Validators.required],
          'addres': [data[0].addres, Validators.required],
          'email': [data[0].email, Validators.required],
        });
      });
  }

}
