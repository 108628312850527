import { Component, OnInit, ViewChild } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import Image from 'src/app/models/images';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-edite-image',
  templateUrl: './edite-image.component.html',
  styleUrls: ['./edite-image.component.css']
})
export class EditeImageComponent implements OnInit {

  imageData: Image;
  editedId: string;

  editeForm: FormGroup;
  ImageFile: File;
  @ViewChild('proImg') proImg;

  constructor(private ism: ImagesService,
              private ar: ActivatedRoute,
              private fb: FormBuilder,
              private router: Router) {
    this.editeForm = this.fb.group({
      'name': ['', Validators.required],
      'description': ['', Validators.required],
      'isSlider': [''],
      'proImg': ['', Validators.required]
    });
  }

  OnSubmit(value) {
    const img = this.proImg.nativeElement;
    if (img.files && img.files[0]) {
      this.ImageFile = img.files[0];
    }

    const formData = new FormData();
    formData.append('name', value.name);
    formData.append('description', value.description);
    formData.append('isSlider', value.isSlider);
    if (img.files && img.files[0]) {
      this.ImageFile = img.files[0];
      formData.append('proImg', img.files[0], img.files[0].name);
    }

    this.ism.editImage(this.editedId, formData).subscribe(() => {
      this.router.navigate(['/admin-illustration']);
    });

  }

  ngOnInit() {
    this.editedId = this.ar.snapshot.paramMap.get('id');
    this.ism.getImage(this.ar.snapshot.paramMap.get('id'))
      .subscribe((data: Image) => {
        this.editeForm = this.fb.group({
          'name': [data.name, Validators.required],
          'description': [data.description, Validators.required],
          'isSlider': [data.isSlider],
          'proImg': ['', Validators.required]
        });
      });
  }

}
