import { Component, OnInit, Input } from '@angular/core';

import { ImagesService } from 'src/app/services/images.service';
import Image from 'src/app/models/images';
import { environment } from 'src/environments/environment';
import { NavLighterComponent } from '../nav/nav-lighter/nav-lighter.component';

@Component({
  selector: 'app-illustration',
  templateUrl: './illustration.component.html',
  styleUrls: ['./illustration.component.css']
})
export class IllustrationComponent implements OnInit {

  @Input() navingbar: NavLighterComponent;

  uri = environment.baseUrl;

  images: Image[];
  screenHeight;
  screenWidth;
  navToggle = true;

  constructor(private ims: ImagesService) { }

  openSlider(id, alt) {
    this.navToggle = false;

    const model = document.getElementById('model' + id);
    const captionText = document.getElementById('caption');

    model.style.display = 'block';
    captionText.innerHTML = alt;
  }

  close(id) {
    this.navToggle = true;
    const model = document.getElementById('model' + id);
    model.style.display = 'none';
  }

  ngOnInit() {
    this.screenHeight = window.innerWidth;
    this.screenWidth = window.innerWidth;
    this.ims.getImages()
      .subscribe((data: Image[]) => {
        this.images = data;
      });
  }

}
